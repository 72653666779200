// 限时抢购
<template>
  <div>
    <common-header></common-header>
    <div class="buy-limit warp">
      <div class="bj"></div>
      <div class="content">
        <img class="limit-title" src="@/assets/images/img/limite-kill.png" />
        <!-- 时间 start -->
        <div class="times-box">
          <div class="tit">距离结束</div>
          <div class="count-down">
            <div class="st">{{downObj.hour}}</div>:<div class="st">{{downObj.minute}}</div>:<div class="st">{{downObj.second}}</div>
          </div>
        </div>
        <!-- 时间 end -->
    

        <!-- 商品列表轮播 start -->
        <div class="goods-swiper-box">
          <ul class="goods-ul">
            <li class="goods-li" v-for="(item, index) in da.specList" :key="index" @click="$router.push({path: '/shopping/details', query: {id: item.commodityId, specid: item.commoditySpecId }})">
              <div class="good-imgbox pre">
                <img class="goods-img" :src="getGoodsImg(item)" alt="">
                <span class="quehuo" v-if="item.orderQuantity>=item.seckillQuantity">已抢光</span>
              </div>

              <div class="right">
                <div class="name">{{item.commodityName}}</div>
                <div class="ms">{{item.specName}}</div>
                <div class="org-price">现价￥{{item.price}}</div>
                <div class="anniu-box">
                  <div class="bottom-box">
                    <div class="l">
                      <i class="icon-hot-ms"></i>
                      秒杀价
                    </div>
                    <div class="purchase jb-r-l"><span class="unit">￥</span>{{item.seckillPrice}}
                      <span class="unit" v-if="item.unitName">/{{item.unitName}}</span>
                    </div>
                  </div>
                  <div class="icon-car-btn" v-if="item.orderQuantity<item.seckillQuantity" @click.stop="SelectProducts(item.commodityId, item.commoditySpecId, item.inventory)"></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- 商品列表轮播 end -->

      </div>


    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { requestImgUrl, getGoodsImg, CountDown,DateFormat  } from "@/utils/common";
export default {
  data() {
    this.CountDown = v => CountDown(v);
    this.DateFormat = v => DateFormat(v);
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    this.getGoodsImg = v => getGoodsImg(v);
    return {
      uid: localStorage.getItem('uid'),
      da: [],
      // 倒计时
      downObj: {},
    }
  },
  mounted() {
    this.GetSeckillList().then((res)=> {
      let datas = res.data.filter((e)=> e.status == 0);
      this.da = datas[0];
      this.MsDownTime(datas[0].endTime);
    })
  },
  methods: {
    // 选择商品事件
    SelectProducts(commodityId, specId, inventory) {
      if(inventory <= 0) {
        this.$toast("卖光啦！");
        return false;
      }
      this.AddCommodityCart({
        commodityId: commodityId,
        specId: specId,
        uid: this.uid,
      }).then((res)=> {
        if (res.status == 200) {
          this.$toast("加入购物车成功")
          // 获取购物车总数
        }
      })
    },
    // 限时秒杀商品倒计时
    MsDownTime(endTime) {
      // 计算倒计时
      let nowDate = this.DateFormat(new Date());
      let endTimeRub = new Date(endTime.replace(/-/g,'/')).getTime();
      // 当前时间时间搓
      let currentTime = new Date(nowDate.replace(/-/g,'/')).getTime();
      let differTime = (endTimeRub - currentTime) / 1000;
      this.timer = setInterval(()=> {
        differTime = differTime - 1;
        if (differTime <= 0) {
          clearInterval(this.timer);
        }
        this.downObj = this.CountDown(differTime);
      },1000)
      this.downObj = this.CountDown(differTime);
    },
    ...mapActions('shopping', [
      'GetSeckillList',
      'AddCommodityCart',
    ])
  },
}
</script>
<style lang='less' scoped>
.anniu-box{
  display: flex;
  align-items: center;
  margin-top: .3rem;
  justify-content: space-between;
}
.times-box{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-top: .15rem;
}
.count-down{
  display: flex;
  color: #fff;
  margin-left: .15rem;
  align-items: center;
  .st{
    min-width: .55rem;
    padding: 0 .1rem;
    height: .55rem;
    background: #fff;
    font-size: .3rem;
    color: #ff7341;
    text-align: center;
    border-radius: .05rem;
    line-height: .55rem;
    margin: 0 .1rem;
  }
}
.limit-title{
  width: 2.25rem;
  display: block;
  margin: auto;
}
.bottom-box{
  display: flex;
  justify-content: space-between;
  width: 3.32rem;
  height: .58rem;
  border-radius: 2.85rem;
  background: #ffe7dc;
  .l{
    display: flex;
    align-items: center;
    font-size: .26rem;
    color: #904b33;
    padding-left: .18rem;
    i{
      margin-right: .05rem;
    }
  }
  .purchase{
    width: 1.6rem;
    height: .58rem;
    border-radius: .3rem;
    font-size: .3rem;
    color: #ffffff;
    text-align: center;
    line-height: .58rem;
    font-size: .36rem;
    color: #ffffff;
    .unit{
      font-size: .24rem;
      color: #ffffff;
    }
  }
}
.goods-swiper-box{
  margin-top: .3rem;
}
.goods-li{
  background: #fff;
  border-radius: .1rem;
  display: flex;
  padding: .2rem;
  margin-bottom: .2rem;
  .right{
    flex: 1;
  }
  .price-box{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .org-price{
    font-size: .26rem;
    color: #bfbfbf;
    text-decoration: line-through;
    line-height: 1;
  }
  .present-price{
    display: flex;
    align-items: center;
    color: #ff7241;
    .unit{
      font-size: .24rem;
    }
    .moeny{
      font-size: .42rem;
      line-height: 1;
    }
  }
  .name{
    font-size: .3rem;
    color: #262626;
  }
  .ms{
    font-size: .26rem;
    color: #bfbfbf;
    margin-top: .1rem;
    margin-bottom: .22rem;
  }
  .goods-img{
    width: 2.2rem;
    height: 2.2rem;
    margin-right: .2rem;
  }
  .progress-box{
    width: 2rem;
    position: relative;
    .text{
      position: absolute;
      color: #fff;
      font-size: .18rem;
      text-align: center;
      width: 100%;
      top: 0;
      left: 0;
      line-height: .2rem;
    }
  }
  .quehuo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 5;
    background-color: rgba(167, 167, 167, 0.7);
    color: #fff;
    width: 1.4rem;
    height: 1.4rem;
    font-size: 0.32rem;
    text-align: center;
    line-height: 1.4rem;
    display: block;
    border-radius: 1rem;
  }
}
.time-slide{
  width: 1.44rem !important;
  height: 1.1rem;
}
.time-item{
  width: 1.44rem !important;
  height: .96rem;
  border-radius: .05rem;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;
  opacity: .5;
  &.active{
    opacity: 1;
    position: relative;
    &::before{
      position: absolute;
      transform: rotate(180deg);
      bottom: -.15rem;
      left: 50%;
      margin-left: -.075rem;
      content: "";
      width: 0;
      height: 0;
      border-width: 0px .15rem .15rem;
      border-style: solid;
      border-color: transparent transparent #fff;
    }
  }
  .hours{
    font-size: .32rem;
    color: #262626;
  }
  .ms{
    font-size: .22rem;
    color: #262626;
  }
}
.buy-limit{
  position: relative;
  padding-top: .35rem;
  .content{
    position:relative;
    z-index: 2;
  }
  .bj{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4.5rem;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff7d4a+0,f7f7f7+100 */
    background: #ff7d4a; /* Old browsers */
    background: -moz-linear-gradient(top, #ff7d4a 0%, #f7f7f7 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #ff7d4a 0%,#f7f7f7 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #ff7d4a 0%,#f7f7f7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7d4a', endColorstr='#f7f7f7',GradientType=0 ); /* IE6-9 */
  }
}
</style>


